var chart = Highcharts.chart('chart', {
  chart: {
    type: 'bar',
    style: {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '500'
    },
    backgroundColor: 'transparent'
  },
  credits: {
  	enabled: false
  },
	colors: ['#057ABA','#43820F'],
  title: {
    text: 'Baten en lasten per taakveld',
    style: {
      fontSize: '24px'
    }
  },
  subtitle: {
    text: '(in € 1.000)',
    style: {
      fontSize: '18px'
    }
  },
  legend: {
    verticalAlign: 'top'
  },
  plotOptions: {
    series: {
      groupPadding:0.1,
      pointWidth: 20
    }
  },
  xAxis: {
    categories: ['Bestuur en ondersteuning', 'Verkeer, vervoer en waterstaat', 'Veiligheid', 'Economie', 'Onderwijs', 'Sport, cultuur en recreatie', 'Sociaal domein', 'Huisvesting en ruimtelijke ordening', 'Gezondheid en milieu'],
    tickLength: 0,
    labels: {
      style: {
        fontSize: '13px'
      }
    }
  },
  yAxis: {
    breaks:[{
      from: 15000,
      to: 20000
    },{
      from: 30000,
      to: 40000
    },{
      from: 45000,
      to: 75000
    }],
    labels: {
    	autoRotation:false,
      autoRotationLimit:0,
      formatter: function(){
      	return this.value / 1000;
      },
      style: {
        fontSize: '14px'
      }
    },
    max: 80000,
    tickInterval: 20000,
    title: {
      text: ''
    }
  },
  series: [{
    name: 'Baten',
    data: [77650, 1998, 137, 12968, 207, 1314, 10867, 8064, 9114]
  }, {
    name: 'Lasten',
    data: [21529, 7555, 3420, 9874, 5260, 11759, 43838, 7992, 10146]
  }]
});
